import { isShift } from '~/utils/isShift'

export type Favicon = '/shift-icon-32.png' | '/ON_icon.png'

function getCurrentRootFavicon(): Favicon {
  if (isShift()) {
    return '/shift-icon-32.png'
  }

  return '/ON_icon.png'
}

export default function useRootFavicon(): Ref<Favicon | undefined> {
  return useState<Favicon | undefined>('favicon', getCurrentRootFavicon)
}
